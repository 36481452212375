import { IGeneralStudy, IImagePrefetch, IPatientStudy, Priority } from '@app/@core';
import { IStudyViewer } from '@app/viewer/models/IStudyViewer';
import { createFeature, createReducer, on } from '@ngrx/store';
import { studyListAction, viewerDriveActions, viewerFileActions, viewerGeneralActions } from './general.actions';
import { IPatientInfo } from '@app/viewer/models/IPatientInfo';
import { IStackImage } from '@app/viewer/models/IStackImage';
import { ISeriesInfo } from '@app/viewer/models/ISeriesInfo';
import { ISeriesToStack, IViewportBackup } from '@app/viewer/models/IViewport';
import { cloneDeep } from 'lodash';
import { ViewerMode } from '@app/viewer/contants/mode';
import { mutableOn } from 'ngrx-etc';
export const VIEWER_GENERAL_KEY = 'general';
export interface ViewerGeneralState {
  isLoading: boolean;
  //DICOM DATA
  stackImages: IStackImage[];
  //Study table data
  studyInfo: IGeneralStudy[];
  //Patient info data
  patientInfo: IPatientInfo[];
  seriesInfos: ISeriesInfo[];
  seletedStudies: IGeneralStudy[];
  displayViewport: ISeriesToStack[];
  displayViewportBackup: ISeriesToStack[];
  viewportBackup: IViewportBackup[];
  mode: ViewerMode;
  isFullImage: boolean;
  guestMode: boolean;
  shareMode: boolean;
  isAnonymous: boolean;
  fromDrive: boolean;
  error: any;
}

export const initialState: ViewerGeneralState = {
  error: null,
  isLoading: true,
  patientInfo: [
    {
      patientName: '',
      patientId: '',
      patientGender: '',
      patientAge: '',
      studyUid: '',
      avatar: '',
    },
  ],
  studyInfo: [],
  stackImages: [],
  seriesInfos: [],
  seletedStudies: [],
  displayViewport: [],
  displayViewportBackup: [],
  viewportBackup: [],
  mode: ViewerMode.Stack2D,
  guestMode: false,
  shareMode: false,
  fromDrive: false,
  isFullImage: false,
  isAnonymous: false,
};

export const generalFeature = createFeature({
  name: VIEWER_GENERAL_KEY,
  reducer: createReducer<ViewerGeneralState>(
    initialState,
    on(
      viewerGeneralActions.startGuestMode,
      (state, action): ViewerGeneralState => ({
        ...state,
        guestMode: true,
      }),
    ),
    on(
      viewerDriveActions.load,
      (state, action): ViewerGeneralState => ({
        ...state,
        fromDrive: true,
      }),
    ),
    on(
      viewerGeneralActions.startShareMode,
      (state, action): ViewerGeneralState => ({
        ...state,
        shareMode: true,
      }),
    ),
    on(
      viewerDriveActions.loadShareWithoutLogin,
      (state, action): ViewerGeneralState => ({
        ...state,
        guestMode: true,
      }),
    ),
    //#region File Dicom
    on(
      viewerFileActions.load,
      (state): ViewerGeneralState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      viewerFileActions.loadSuccess,
      (state, { payload }): ViewerGeneralState => ({
        ...state,
        isLoading: false,
        isAnonymous: false,
        stackImages: [...state.stackImages, ...payload.stackImages],
        studyInfo: [...state.studyInfo, ...payload.studyInfo],
        patientInfo: [...state.patientInfo, ...payload.patientInfo],
        seriesInfos: [...state.seriesInfos, ...payload.seriesInfos],
      }),
    ),
    on(
      viewerFileActions.loadFail,
      (state, action): ViewerGeneralState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
    //#endregion
    //#region Google Drive dicom dir
    on(
      viewerDriveActions.load,
      (state): ViewerGeneralState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      viewerDriveActions.loadSuccess,
      (state, { payload, isAnonymous }): ViewerGeneralState => ({
        ...state,
        isLoading: false,
        isAnonymous: isAnonymous || false,
        stackImages: [...state.stackImages, ...payload.stackImages],
        studyInfo: [...state.studyInfo, ...payload.studyInfo],
        patientInfo: [...state.patientInfo, ...payload.patientInfo],
        seriesInfos: [...state.seriesInfos, ...payload.seriesInfos],
      }),
    ),
    on(
      viewerDriveActions.loadFail,
      (state, action): ViewerGeneralState => ({
        ...state,
        isLoading: false,
        error: action.error,
      }),
    ),
    on(viewerDriveActions.downloadSuccess, (state, action): ViewerGeneralState => {
      //need sparate update status download for series not download image because of no no need update stack image in store for performance
      if (action.images.length === 0) {
        const seriesInfos = cloneDeep(state.seriesInfos);
        seriesInfos.map((series) => {
          if (series.uid === action.seriesUid) {
            series.isDownloading = false;
          }
        });
        return { ...state, seriesInfos };
      }

      const updateStack = cloneDeep(state.stackImages);

      //get Stack of image has been downloaded
      const stack = updateStack.find((stack) => stack.uid === action.seriesUid);
      action.images.map((image: IImagePrefetch) => {
        if (stack) {
          const rest = stack.imageDriveId?.filter((id) => id !== image.imageId);
          stack.imageDriveId = rest?.length === 0 ? [] : rest;
          if (image.isMultiFrame) {
            image.frameId?.forEach((frameId) => {
              const newImage = { ...image };
              newImage.imageId = frameId;
              stack.imagePrefetch.push(newImage);
            });
          } else {
            stack.imagePrefetch.push(image);
          }
        }
      });

      const seriesInfos = cloneDeep(state.seriesInfos);
      seriesInfos.map((series) => {
        if (series.uid === action.seriesUid) {
          series.isDownloading = false;
        }
      });
      return { ...state, seriesInfos, stackImages: updateStack };
    }),
    mutableOn(viewerDriveActions.downloadLowPrioritySuccess, (state, action): ViewerGeneralState => {
      if (action.images.length === 0) {
        // const seriesInfos = cloneDeep(state.seriesInfos);
        // seriesInfos.map((series) => {
        //   if (series.uid === action.seriesUid) {
        //     series.isDownloading = false;
        //   }
        // });
        // return { ...state, seriesInfos };
        const updatedSeries = state.seriesInfos.find((series) => series.uid === action.seriesUid);
        if (updatedSeries) {
          updatedSeries.isDownloading = false;
        }
        return state;
      }

      // const updateStack = cloneDeep(state.stackImages);

      //get Stack of image has been downloaded
      const stack = state.stackImages.find((stack) => stack.uid === action.seriesUid);
      action.images.map((image: IImagePrefetch) => {
        if (stack) {
          const rest = stack.imageDriveId?.filter((id) => id !== image.imageId);
          stack.imageDriveId = rest?.length === 0 ? [] : rest;
          if (image.isMultiFrame) {
            image.frameId?.forEach((frameId) => {
              const newImage = { ...image };
              newImage.imageId = frameId;
              stack.imagePrefetch.push(newImage);
            });
          } else {
            stack.imagePrefetch.push(image);
          }
        }
      });

      // const seriesInfos = cloneDeep(state.seriesInfos);
      // seriesInfos.map((series) => {
      //   if (series.uid === action.seriesUid) {
      //     series.isDownloading = false;
      //   }
      // });
      const updatedSeries = state.seriesInfos.find((series) => series.uid === action.seriesUid);
      if (updatedSeries) {
        updatedSeries.isDownloading = false;
      }
      return state;
    }),
    //#endregion
    //#region Viewer
    on(
      viewerGeneralActions.initViewerData,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: action.displayViewport,
      }),
    ),
    on(
      viewerGeneralActions.changeSelectedStudy,
      (state, action): ViewerGeneralState => ({
        ...state,
        seletedStudies: action.payload,
      }),
    ),
    on(viewerGeneralActions.replaceSeriesInStack, (state, action): ViewerGeneralState => {
      const oldDisplayViewport = cloneDeep(state.displayViewport.filter((viewport) => viewport.stackIndex !== action.info.stackIndex));
      return {
        ...state,
        displayViewport: [...oldDisplayViewport, action.info],
      };
    }),
    on(
      viewerGeneralActions.changeViewerMode,
      (state, action): ViewerGeneralState => ({
        ...state,
        mode: action.mode,
      }),
    ),
    on(
      viewerGeneralActions.backupDisplayViewport,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewportBackup: action.viewports,
      }),
    ),
    on(
      viewerGeneralActions.backupDisplayViewportFullImage,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewportBackup: action.viewports,
      }),
    ),
    on(
      viewerGeneralActions.restoreDisplayViewport,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: action.viewports,
        mode: ViewerMode.Stack2D, // Reset to default 2D stack after restoring from backup
      }),
    ),
    on(
      viewerGeneralActions.restoreDisplayViewportFullImage,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: action.viewports,
      }),
    ),
    on(
      viewerGeneralActions.onDisplayFullImage,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: [action.displayViewport],
      }),
    ),
    on(
      viewerGeneralActions.changeDisplayViewportVolume,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: cloneDeep(action.displayViewport),
      }),
    ),
    on(
      viewerGeneralActions.changeDisplayViewport,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: action.displayViewport,
      }),
    ),
    on(
      viewerGeneralActions.changeDisplayVolume2DSuccess,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: cloneDeep(action.displayViewport),
      }),
    ),
    on(
      viewerGeneralActions.activeFullImage,
      (state, action): ViewerGeneralState => ({
        ...state,
        isFullImage: !state.isFullImage,
      }),
    ),
    on(viewerGeneralActions.backupViewport, (state, action): ViewerGeneralState => {
      const oldBackupViewport = cloneDeep(state.viewportBackup.filter((viewport) => viewport.tileIndex !== action.viewport.tileIndex));
      return {
        ...state,
        viewportBackup: [...oldBackupViewport, action.viewport],
      };
    }),
    on(viewerGeneralActions.changeDisplaySeries, (state, action): ViewerGeneralState => {
      const oldDisplayViewports = cloneDeep(state.displayViewport.filter((viewport) => viewport.stackIndex !== action.currentStackIndex));
      if (action.displayViewport) {
        return {
          ...state,
          displayViewport: [...oldDisplayViewports, action.displayViewport],
        };
      } else {
        return {
          ...state,
          displayViewport: [...oldDisplayViewports],
        };
      }
    }),
    //#endregion
    //#region Study List

    on(
      studyListAction.studyDisplayCurrentLayoutSuccess,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: action.displayViewport,
        viewportBackup: [],
      }),
    ),
    on(
      studyListAction.studyDisplayBySettingSuccess,
      (state, action): ViewerGeneralState => ({
        ...state,
        viewportBackup: [],
      }),
    ),
    on(
      studyListAction.studyDisplayAllSeries,
      (state, action): ViewerGeneralState => ({
        ...state,
        displayViewport: [],
      }),
    ),
    //#endregion
  ),
});

export const { name, reducer } = generalFeature;
