import { IDBStudy } from '@app/@database';
import { IPatientInfo, IReportContent } from '../models';
import { ReportState, REPORT_KEY } from './report.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGeneralStudy, MODALITY_SEPARATOR } from '@app/@core';

//#region  Entities

/**
 * Selects the worklist state from the feature state.
 *
 * @param state - The feature state.
 * @returns The worklist state.
 */
const selectReportState = createFeatureSelector<ReportState>(REPORT_KEY);

/**
 * Selects the study information list from the viewer state.
 * @returns {MemoizedSelector<object, StudyInfo[]>} The memoized selector function.
 */
const selectStudyInfoList = createSelector(selectReportState, (state: ReportState) => state.studyInfo);

const selectPatientInfos = createSelector(selectReportState, (state: ReportState) => state.patientInfo);

const selectClinicInfos = createSelector(selectReportState, (state: ReportState) => state.clinicInfo);

const selectReportContents = createSelector(selectReportState, (state: ReportState) => state.contents);

const selectIsReadOnly = createSelector(selectReportState, (state: ReportState) => state.readOnly);

const selectShareMode = createSelector(selectReportState, (state: ReportState) => state.shareMode);

const selectIsAnonymous = createSelector(selectReportState, (state: ReportState) => state.isAnonymous);

const selectSelectedStudy = createSelector(selectReportState, (state: ReportState) => state.selectedStudy);
//#endregion

//#region  models
/**
 * Selects the patient information from the viewer state.
 * @returns {MemoizedSelector<object, PatientInfo>} The memoized selector function.
 */
const selectPatientInfoByStudyUid = (studyUid: string) => createSelector(selectPatientInfos, (state: IPatientInfo[]) => state.find((patient) => patient.studyUid === studyUid));
//#endregion

/**
 * Selects the clinic information for a given study UID.
 *
 * @param studyUid - The study UID to filter the clinic information.
 * @returns The clinic information for the specified study UID.
 */
const selectClinicInfoByStudyUid = (studyUid: string) => createSelector(selectClinicInfos, (state: IDBStudy[]) => state.find((study) => study.studyInstanceUid === studyUid));

/**
 * Selects the unique modalities from the study information in the viewer general state.
 *
 * @param state - The viewer general state.
 * @returns An array of unique modalities.
 */
const selectModalityFilter = createSelector(selectStudyInfoList, (state: IGeneralStudy[]) => {
  const modality = state.map((study) => study.modality).flatMap((modality) => modality.split(MODALITY_SEPARATOR));
  const result = modality.filter((value, index) => modality.indexOf(value) === index);
  return result;
});

/**
 * Selects the history reports for a specific study and user.
 *
 * @param studyInstanceUid - The study instance UID.
 * @param userId - The ID of the user.
 * @returns An array of history reports for the specified study and user.
 */
const selectHistoryReports = (studyInstanceUid: string, userId: string) =>
  createSelector(selectReportContents, (state: IReportContent[]) => {
    const studyReports = state.filter((report) => report.studyInstanceUid === studyInstanceUid && report.createBy !== userId);
    return studyReports;
  });

/**
 * Selects the current report based on the study UID and user ID.
 *
 * @param studyInstanceUid - The study instance UID.
 * @param userId - The user ID to filter the reports.
 * @returns The current report that matches the study UID and user ID.
 */
const selectCurrentReport = (studyInstanceUid: string, userId: string) =>
  createSelector(selectReportContents, (state: IReportContent[]) => {
    const currentReport = state.find((report) => report.studyInstanceUid === studyInstanceUid && report.createBy === userId);
    return currentReport;
  });

export {
  selectReportState,
  selectStudyInfoList,
  selectPatientInfos,
  selectIsReadOnly,
  selectSelectedStudy,
  selectShareMode,
  selectIsAnonymous,
  selectPatientInfoByStudyUid,
  selectClinicInfoByStudyUid,
  selectModalityFilter,
  selectHistoryReports,
  selectCurrentReport,
};
