import { Injectable } from '@angular/core';
import { OPEN_VIEWER_PATTERN } from '@app/@core';
import { StudyFirebaseService } from '@app/@database';
import { GoogleDriveService } from '@app/@shared';

@Injectable()
export class WorklistService {
  constructor(
    private studyService: StudyFirebaseService,
    private driveService: GoogleDriveService,
  ) {}

  /**
   * Shares the current study with the specified user.
   *
   * @param studyId - The ID of the study to be shared.
   * @param dicomdirID - The ID of the DICOM directory.
   * @param userId - The ID of the user with whom the study is to be shared.
   * @returns A promise that resolves to a boolean indicating whether the study was successfully shared.
   *
   * @remarks
   * - Checks if the study is already shared with the user.
   * - If not shared, finds the parent drive ID of the DICOM directory.
   * - Shares the Google Drive folder with all users.
   * - Updates the study's `isShared` flag to true.
   * - Handles errors by logging them and returning `false`.
   */
  public shareCurrentStudy = async (studyId: string, dicomdirID: string, userId: string): Promise<boolean> => {
    try {
      //check current study is shared or not
      const isShared = await this.studyService.isSharedStudy(studyId, userId);
      if (isShared) {
        return true;
      } else {
        const baseDirveId = await this.driveService.findParentDriveId(dicomdirID);
        if (baseDirveId) {
          //Share google drive folder to all user
          const shareId = await this.driveService.shareFile(baseDirveId);
          //update study isShared flat to true
          const updatePayload = { isShared: true, isAnonymous: false };
          const res = await this.studyService.updatePartialStudy(updatePayload, studyId, userId);
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error('Error in sharing study', error);
      return false;
    }
  };
}
