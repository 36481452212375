import { IGeneralStudy } from '@app/@core';
import { createFeature, createReducer, on } from '@ngrx/store';
import { IPatientInfo, IReportContent } from '@app/report/models';
import { reportActions } from './report.actions';
import { IDBStudy } from '@app/@database';
import { mutableOn } from 'ngrx-etc';

export const REPORT_KEY = 'report';
export interface ReportState {
  isLoading: boolean;
  //Study table data
  studyInfo: IGeneralStudy[];
  //Patient info data
  patientInfo: IPatientInfo[];
  clinicInfo: IDBStudy[];
  contents: IReportContent[];
  error: any;
  readOnly: boolean;
  shareMode: boolean;
  isAnonymous: boolean;
  selectedStudy: string;
}

export const initialState: ReportState = {
  error: null,
  isLoading: true,
  patientInfo: [],
  studyInfo: [],
  clinicInfo: [],
  contents: [],
  readOnly: false,
  shareMode: false,
  isAnonymous: false,
  selectedStudy: '',
};

export const reportFeature = createFeature({
  name: REPORT_KEY,
  reducer: createReducer<ReportState>(
    initialState,
    on(
      reportActions.load,
      (state): ReportState => ({
        ...state,
        isLoading: true,
      }),
    ),
    on(
      reportActions.loadSuccess,
      (state, { payload, isReadOnly, isShare, isAnonymous }): ReportState => ({
        ...state,
        isLoading: false,
        readOnly: isReadOnly,
        isAnonymous,
        shareMode: isShare,
        studyInfo: payload.studyInfos,
        patientInfo: payload.patients,
        clinicInfo: payload.clinicInfo,
        contents: payload.reportContents,
      }),
    ),
    mutableOn(reportActions.reloadSuccess, (state, action): ReportState => {
      const updateContent = state.contents.find((content) => {
        content.studyInstanceUid === action.payload.studyInstanceUid;
      });
      if (updateContent) {
        updateContent.comment = action.payload.comment;
        updateContent.diagnosis = action.payload.diagnosis;
        updateContent.diagnosis = action.payload.doctorUid;
        updateContent.figure = action.payload.figure;
        updateContent.finding = action.payload.finding;
        updateContent.keyImages = action.payload.keyImages;
        updateContent.updatedDate = action.payload.updatedDate;
      }
      return state;
    }),
    on(
      reportActions.loadFail,
      (state, { error }): ReportState => ({
        ...state,
        isLoading: false,
        error,
      }),
    ),
    on(
      reportActions.changeSelectedStudy,
      (state, { studyUid }): ReportState => ({
        ...state,
        selectedStudy: studyUid,
      }),
    ),
  ),
});

export const { name, reducer } = reportFeature;
