import { createAction, emptyProps, props } from '@ngrx/store';
import { IStudyInfo } from '../models/IStudyInfo';
import { TableLazyLoadEvent } from 'primeng/table';
import { IWorklistData } from '../models/IDatatable';
import { ICondition, IDBStudy } from '@app/@database';
import { ISearch } from '../models/ISearch';
import { IFilter } from '../models/IFilter';
import * as exp from 'constants';

/**
 * Action to load the worklist.
 */
export const loadWorklist = createAction('[Worklist] Load Worklist');

/**
 * Searches the worklist with the specified payload.
 *
 * @param payload - The search criteria.
 * @returns An action object with the payload.
 */
export const searchWorklist = createAction('[Worklist] Search Worklist');

/**
 * Action to update the search payload.
 *
 * @param payload - The search criteria.
 * @returns An action object with the payload.
 */
export const updateSearchPayload = createAction('[Worklist] Update Search Payload', props<{ payload: ISearch | null }>());

/**
 * Action to clear the search payload.
 */
export const clearSearchPayload = createAction('[Worklist] Clear Search Payload');
/**
 * Action to filter the worklist.
 *
 * @param payload - The filter object containing the filter criteria.
 * @returns An action object with the payload.
 */
export const FilterWorklist = createAction('[Worklist] Filter Worklist', props<{ payload: IFilter }>());

/**
 * Action to be dispatched when the worklist is successfully loaded.
 * @param worklist - The array of patient information representing the loaded worklist.
 */
export const loadWorklistSuccess = createAction('[Worklist] Load Worklist Success', props<{ worklist: IWorklistData[]; totalRecords: number }>());

/**
 * Action to be dispatched when loading the worklist fails.
 * @param error - The error object representing the failure.
 * @returns An action object with the error payload.
 */
export const loadWorklistFailure = createAction('[Worklist] Load Worklist Failure', props<{ error: any }>());

/**
 * Action creator for opening a report in the worklist.
 */
export const openReport = createAction('[Worklist] Open Report');

/**
 * Closes a report in the worklist.
 */
export const closeReport = createAction('[Worklist] Close Report');

/**
 * Action to share a study in the worklist.
 *
 * @param study - The study to be unshared.
 */
export const shareStudy = createAction('[Worklist] Study Share', props<{ study: IWorklistData }>());


export const shareStudyAnonymous = createAction('[Worklist] Study Share Anonymous', props<{ study: IWorklistData }>());

/**
 * Action to share a study in the worklist.
 *
 * @param study - The study to be shared.
 */
export const shareStudySuccess = createAction('[Worklist] Study Share Success', props<{ study: IDBStudy }>());

/**
 * Action to unshare a study in the worklist.
 *
 * @param study - The study to be unshared.
 */
export const unshareStudy = createAction('[Worklist] Study Unshare', props<{ study: IWorklistData }>());
/**
 * Action creator for successful unsharing of a study in the worklist.
 *
 * @param study - The study that was unshared.
 * @returns An action object with the study information.
 */
export const unshareStudySuccess = createAction('[Worklist] Study Unshare Success', props<{ study: IDBStudy }>());
