import { Injectable } from '@angular/core';
import { GoogleDriveService } from '@app/@shared';
import { IReportDir } from '../models/IReportDir';
import { DRIVE_FILE_EXTENSION, DRIVE_FILE_NAME, DRIVE_MIMETYPE } from '@app/@core';
import { HttpStatusCode } from '@angular/common/http';
import { IDBReport, ReportFirebaseService } from '@app/@database';
import { environment } from '@env/environment';

@Injectable()
export class ReportService {
  constructor(
    private driveService: GoogleDriveService,
    private reportFirebaseService: ReportFirebaseService,
  ) {}

  /**
   * Retrieves the contents of multiple reports based on the provided drive IDs.
   * @param driveIds - An array of drive IDs representing the reports to retrieve.
   * @returns A promise that resolves to an array of report directories.
   */
  getReportContents = async (driveIds: string[]): Promise<IReportDir[]> => {
    try {
      const tasks: Promise<any>[] = [];
      for (const id of driveIds) {
        tasks.push(this.driveService.getFileContent(id));
      }
      const res = await Promise.all(tasks);
      return res;
    } catch (error) {
      return [];
    }
  };

  /**
   * Retrieves the content of a report.
   *
   * @param driveId - The ID of the drive.
   * @returns A promise that resolves to an instance of IReportDir if successful, or null if an error occurs.
   */
  getReportContent = async (driveId: string): Promise<IReportDir | null> => {
    try {
      const res = await this.driveService.getFileContent(driveId);
      return res;
    } catch (error) {
      return null;
    }
  };

  /**
   * Saves the report content to the specified base folder.
   * @param baseFolder - The base folder where the report content will be saved.
   * @param payload - The report directory payload.
   * @param userId - The user ID.
   * @param isShareMode - A boolean indicating whether the report is in share mode.
   * @returns A Promise that resolves to the drive ID of the saved report content, or an empty string if the save operation fails.
   */
  saveReportContent = async (baseFolder: string, payload: IReportDir, userId: string, isShareMode: boolean): Promise<string> => {
    let response;
    if (isShareMode) {
      //Get the report shared folder of the user
      //upload signature file to google drive
      let rootFolderId = await this.driveService.getFileOrFolderId(environment.drive.root_path, DRIVE_MIMETYPE.FOLDER);
      if (rootFolderId === '') {
        rootFolderId = await this.driveService.CreateFolder(environment.drive.root_path);
      }
      let sharedFolderId = await this.driveService.getFileOrFolderId(environment.drive.shareReport, DRIVE_MIMETYPE.FOLDER, rootFolderId);
      if (sharedFolderId === '') {
        sharedFolderId = await this.driveService.CreateFolder(environment.drive.shareReport, rootFolderId);
      }
      response = await this.driveService.uploadFile(sharedFolderId, payload, DRIVE_FILE_NAME.REPORT + userId, DRIVE_FILE_EXTENSION.REPORT, DRIVE_MIMETYPE.JSON);
    } else {
      response = await this.driveService.uploadFile(baseFolder, payload, DRIVE_FILE_NAME.REPORT + userId, DRIVE_FILE_EXTENSION.REPORT, DRIVE_MIMETYPE.JSON);
    }
    if (response.status === HttpStatusCode.Ok) {
      const driveId = response.data.id;
      //set permission for the report content is share with link to implement reference report
      await this.driveService.shareFile(driveId);
      return driveId;
    } else {
      return '';
    }
  };

  /**
   * Deletes the report content with the specified drive ID.
   * @param driveId The ID of the drive containing the report content.
   * @returns A promise that resolves to a boolean indicating whether the deletion was successful.
   */
  deleteReportContent = async (driveId: string): Promise<boolean> => {
    return await this.driveService.deleteFileOrFolder(driveId);
  };

  /**
   * Saves the report metadata to the database.
   *
   * @param payload - The report data to be saved.
   * @returns A Promise that resolves to a string or undefined.
   */
  saveReportMetadata = async (payload: IDBReport): Promise<string | undefined> => {
    const res = await this.reportFirebaseService.saveReport(payload);
    return res;
  };

  /**
   * Deletes the report metadata with the specified UID.
   *
   * @param uid - The UID of the report metadata to delete.
   * @returns A promise that resolves with the result of the deletion.
   */
  deleteReportMetadata = async (uid: string) => {
    const res = await this.reportFirebaseService.deleteReport(uid);
    return res;
  };

  /**
   * Deletes the study metadata and all associated reports.
   * @param studyUid - The unique identifier of the study.
   */
  deleteStudyMetadata = async (studyUid: string) => {
    // Delete all reports associated with the study
    const reports = await this.reportFirebaseService.findReportByStudyUid(studyUid);
    if (!reports || reports.length === 0) {
      return;
    }
    const tasks: Promise<boolean>[] = [];
    for (const report of reports) {
      tasks.push(this.deleteReportMetadata(report.id || ''));
    }
    await Promise.all(tasks);
  };
}
