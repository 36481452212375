import { Injectable } from '@angular/core';
import { FireStoreService } from '@app/@database/firestore/services/firestore.service';
import { COLLECTION, METADATA_KEY } from '@app/@core';
import { IWorklist } from '../models/IWorklist';
import { ICondition, IDBPatient, IDBStudy, PatientFirebaseService, StudyFirebaseService } from '@app/@database';
import { LazyLoadEvent } from 'primeng/api';
import { IStudyInfo } from '../models/IStudyInfo';
import { TableLazyLoadEvent } from 'primeng/table';
import { IWorklistData } from '../models/IDatatable';
import { ISearch } from '../models/ISearch';
import { STUDY_FILTER_KEY, STUDY_SEARCH_KEY } from '@app/@database/firestore/constants/study';
import * as moment from 'moment';
import { IFilter, IFilterType } from '../models/IFilter';
import { getStartEndDate } from '../helpers/datetime';
import { GoogleDriveService } from '@app/@shared';

/**
 * Service for interacting with the worklist database.
 */
@Injectable()
/**
 * Service for managing worklist data in Firebase.
 */
export class WorklistFirebaseService {
  constructor(
    private patientService: PatientFirebaseService,
    private studyService: StudyFirebaseService,
    private driveService: GoogleDriveService,
  ) {}
  //#region OLD LOGIC, NOT USED
  // /**
  //  * Retrieves the worklist data for a given user ID.
  //  * @param userId The ID of the user.
  //  * @returns A promise that resolves to the worklist data.
  //  */
  // async getId(userId: string): Promise<IWorklist> {
  //   const res = <IWorklist>await this.firestoreService.getDataOfCollectionByUserId(COLLECTION.WORKLIST, userId);
  //   return res;
  // }

  // /**
  //  * Sets the drive ID for a given key in the worklist.
  //  * @param key The key of the worklist item.
  //  * @param driveId The drive ID to set.
  //  * @returns A promise that resolves to an empty string.
  //  */
  // async setId(key: string, driveId: string): Promise<string> {
  //   await this.firestoreService.updateData(COLLECTION.WORKLIST, key, { driveId });
  //   return key;
  // }

  // /**
  //  * Initializes the worklist data for a new user.
  //  * @param userId The ID of the new user.
  //  * @param driveId The drive ID to set for the new user.
  //  */
  // async initDataForNewUser(userId: string, driveId: string) {
  //   const body = {
  //     userId,
  //     driveId,
  //   };
  //   const res = await this.firestoreService.addData(COLLECTION.WORKLIST, body);
  //   return res;
  // }
  //#endregion

  //#region NEW LOGIC (backup)

  // /**
  //  * Retrieves the worklist data for a given user ID.
  //  * @param userId The ID of the user.
  //  * @param options The options for lazy loading.
  //  * @param lastStudyId The ID of the last study.
  //  * @returns A promise that resolves to an object containing the count of studies and the worklist data.
  //  */
  // public getWorklistByUserId = async (userId: string, options: TableLazyLoadEvent, lastStudyId?: string): Promise<{ countStudy: number; worklist: IWorklistData[] }> => {
  //   // count total study store on database for pageing
  //   const countStudy = await this.studyService.countStudyByUserId(userId);
  //   // get all study by user
  //   const studies = await this.studyService.getStudyByUserId(userId, options, lastStudyId);
  //   //get all patient of user
  //   const patientUids = studies.map((study) => study.patientUid).filter((e, i, a) => a[i - 1] !== e);
  //   const patients = await this.patientService.getPatientsByUids(patientUids);
  //   const worklist: IWorklistData[] = [];
  //   studies.map((study) => {
  //     const patient = <IDBPatient>patients.find((p) => p.patientId === study.patientId);
  //     worklist.push(this._toWorklistData(study, patient));
  //   });
  //   return {
  //     countStudy,
  //     worklist,
  //   };
  // };

  // /**
  //  * Searches the worklist by user ID and search criteria.
  //  * @param userId - The ID of the user.
  //  * @param payload - The search criteria.
  //  * @returns A promise that resolves to an object containing the count of studies and the worklist data.
  //  */
  // public searchWorklistByUserId = async (userId: string, payload: ISearch): Promise<{ countStudy: number; worklist: IWorklistData[] }> => {
  //   let patientInfo: IDBPatient[] = [];
  //   if (payload.patientId !== '') {
  //     const patient = await this.patientService.getPatientsByPatientId(payload.patientId.trim());
  //     if (patient) {
  //       patientInfo.push(patient);
  //     }
  //   } else if (payload.patientName !== '') {
  //     patientInfo = await this.patientService.getPatientsByName(payload.patientName.trim());
  //   }

  //   if (patientInfo.length === 0 && payload.modalities.length === 0 && payload.studyDateFrom === '' && payload.studyDateTo === '') {
  //     return {
  //       countStudy: 0,
  //       worklist: [],
  //     };
  //   }
  //   //Get all study of patient
  //   const conditions: ICondition[] = [];
  //   const patientIds = patientInfo.map((p) => p.patientId);
  //   // find all study of patient
  //   if (patientInfo.length > 0) {
  //     conditions.push({ field: STUDY_SEARCH_KEY.PATIENT_ID, operator: 'in', value: patientIds });
  //   }

  //   if (payload.modalities.length > 0) {
  //     conditions.push({ field: STUDY_SEARCH_KEY.MODALITY, operator: 'array-contains-any', value: payload.modalities });
  //   }
  //   if (payload.studyDateFrom !== '' && payload.studyDateFrom !== '') {
  //     conditions.push({
  //       field: STUDY_SEARCH_KEY.STUDY_DATE,
  //       operator: '>=',
  //       value: moment(payload.studyDateFrom).format('YYYYMMDD'),
  //     });
  //     conditions.push({
  //       field: STUDY_SEARCH_KEY.STUDY_DATE,
  //       operator: '<=',
  //       value: moment(payload.studyDateTo).format('YYYYMMDD'),
  //     });
  //   }

  //   const studies = await this.studyService.searchStudy(userId, conditions);
  //   if (studies.length === 0) {
  //     return {
  //       countStudy: 0,
  //       worklist: [],
  //     };
  //   }
  //   if (patientInfo.length === 0) {
  //     //get all patient of user
  //     const patientUids = studies.map((study) => study.patientUid).filter((e, i, a) => a[i - 1] !== e);
  //     patientInfo = await this.patientService.getPatientsByUids(patientUids);
  //   }
  //   const worklist: IWorklistData[] = [];
  //   studies.map((study) => {
  //     const patient = <IDBPatient>patientInfo.find((p) => p.patientId === study.patientId);
  //     worklist.push(this._toWorklistData(study, patient));
  //   });
  //   return {
  //     countStudy: worklist.length,
  //     worklist,
  //   };
  // };

  // /**
  //  * Filters the worklist based on the provided payload and user ID.
  //  * @param payload - The filter payload.
  //  * @param userId - The user ID.
  //  * @returns A promise that resolves to an object containing the count of studies and the filtered worklist data.
  //  */
  // public filterWorklist = async (userId: string, payload: IFilter): Promise<{ countStudy: number; worklist: IWorklistData[] }> => {
  //   const conditions: ICondition[] = [];
  //   switch (payload.type) {
  //     case IFilterType.bodypart:
  //       conditions.push({ field: STUDY_FILTER_KEY.BODY_PART, operator: '==', value: payload.filterData });
  //       break;
  //     case IFilterType.modality:
  //       conditions.push({ field: STUDY_FILTER_KEY.MODALITY, operator: '==', value: payload.filterData });
  //       break;
  //     case IFilterType.registerDate:
  //       const { start, end } = getStartEndDate(parseInt(payload.filterData));
  //       conditions.push({ field: METADATA_KEY.CREATE_AT, operator: '>=', value: start });
  //       conditions.push({ field: METADATA_KEY.CREATE_AT, operator: '<=', value: end });
  //       break;
  //     case IFilterType.studyDate:
  //       const studyDate = getStartEndDate(parseInt(payload.filterData));
  //       conditions.push({ field: STUDY_FILTER_KEY.STUDY_DATE, operator: '>=', value: studyDate.start });
  //       conditions.push({ field: STUDY_FILTER_KEY.STUDY_DATE, operator: '<=', value: studyDate.end });
  //       break;
  //     default:
  //       break;
  //   }

  //   const studies = await this.studyService.searchStudy(userId, conditions);
  //   if (studies.length === 0) {
  //     return {
  //       countStudy: 0,
  //       worklist: [],
  //     };
  //   }
  //   const patientUids = studies.map((study) => study.patientUid).filter((e, i, a) => a[i - 1] !== e);
  //   const patientInfo = await this.patientService.getPatientsByUids(patientUids);

  //   const worklist: IWorklistData[] = [];
  //   studies.map((study) => {
  //     const patient = <IDBPatient>patientInfo.find((p) => p.patientId === study.patientId);
  //     worklist.push(this._toWorklistData(study, patient));
  //   });
  //   return {
  //     countStudy: worklist.length,
  //     worklist,
  //   };
  // };

  //#region new logic for all study

  /**
   * Retrieves the worklist data for a given user ID.
   * @param userId The ID of the user.
   * @returns A promise that resolves to an object containing the count of studies and the worklist data.
   */
  public getWorklistByUserId = async (userId: string): Promise<{ countStudy: number; worklist: IWorklistData[] }> => {
    // get all study by user
    const studies = await this.studyService.getStudyByUserId(userId);
    if (studies.length === 0) {
      return {
        countStudy: 0,
        worklist: [],
      };
    }
    //get all patient of user
    const patientUids = studies.map((study) => study.patientUid).filter((e, i, a) => a[i - 1] !== e);
    const patients = await this.patientService.getPatientsByUids(patientUids);
    const worklist: IWorklistData[] = [];
    studies.map((study) => {
      const patient = <IDBPatient>patients.find((p) => p.patientId === study.patientId);
      worklist.push(this._toWorklistData(study, patient));
    });
    return {
      countStudy: worklist.length,
      worklist,
    };
  };
  //#endregion

  /**
   * Converts a study and patient object into a worklist data object.
   * @param study - The study object.
   * @param patient - The patient object.
   * @returns The converted worklist data object.
   */
  private _toWorklistData = (study: IDBStudy, patient: IDBPatient): IWorklistData => {
    return {
      id: study.id || '',
      name: patient.patientName || '',
      patientid: patient.patientId || '',
      sex: patient.gender || '',
      dob: patient.birthDate || '',
      studydate: study.studyDate,
      studytime: study.studyTime,
      bodypart: study.bodyPart,
      modality: study.modality,
      manufacture: study.manufacture,
      accessionNumber: study.accessionNumber,
      studyDescription: study.studyDescription,
      imagecount: study.totalImage,
      studyinstanceUID: study.studyInstanceUid,
      dicomdirID: study.dicomdirDriveId,
      registerdate: study.createAt,
      baseDirveId: study.baseDriveId,
      isShared: study.isShared,
      isHaveReport: study.isHaveReport,
    };
  };

  /**
   * Shares a study with a user.
   *
   * @param studyId - The ID of the study to be shared.
   * @param payload - The study data to be updated.
   * @param userId - The ID of the user to share the study with.
   * @returns A promise that resolves to a boolean indicating whether the sharing was successful.
   */
  public sharingStudy = async (studyId: string, userId: string, worklistData: IWorklistData): Promise<IDBStudy | undefined> => {
    try {
      //Share google drive folder to all user
      const shareId = await this.driveService.shareFile(worklistData.baseDirveId || '');
      //update study isShared flat to true
      const updatePayload = { isShared: true, isAnonymous: false };
      const res = await this.studyService.updatePartialStudy(updatePayload, studyId, userId);
      return res;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  /**
   * Shares a study anonymously by updating its sharing status and sharing the associated Google Drive folder.
   *
   * @param studyId - The ID of the study to be shared.
   * @param userId - The ID of the user initiating the share.
   * @param worklistData - The worklist data containing the base Google Drive ID.
   * @returns A promise that resolves to the updated study data or undefined if an error occurs.
   */
  public sharingStudyAnonymous = async (studyId: string, userId: string, worklistData: IWorklistData): Promise<IDBStudy | undefined> => {
    try {
      //Share google drive folder to all user
      const shareId = await this.driveService.shareFile(worklistData.baseDirveId || '');
      //update study isShared flat to true
      const updatePayload = { isShared: true, isAnonymous: true };
      const res = await this.studyService.updatePartialStudy(updatePayload, studyId, userId);
      return res;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  /**
   * Unshares a study by updating its sharing status and unsharing the associated file or folder.
   *
   * @param {string} studyId - The ID of the study to unshare.
   * @param {string} userId - The ID of the user performing the unsharing action.
   * @param {IWorklistData} worklistData - The worklist data containing the base drive ID.
   * @returns {Promise<IDBStudy | undefined>} - A promise that resolves to the updated study or undefined if an error occurs.
   */
  public unsharingStudy = async (studyId: string, userId: string, worklistData: IWorklistData): Promise<IDBStudy | undefined> => {
    try {
      await this.driveService.unshareFileOrFolder(worklistData.baseDirveId || '', 'anyoneWithLink');
      //update study isShared flat to true
      const updatePayload = { isShared: false, isAnonymous: false };
      const res = await this.studyService.updatePartialStudy(updatePayload, studyId, userId);
      return res;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };
  //#region
}
