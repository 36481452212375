import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IPatientStudy } from '@app/@core';
import { Store } from '@ngrx/store';
import { ViewerGeneralQuery } from '@app/viewer/store/general';
import { IPatientInfo } from '@app/viewer/models/IPatientInfo';

@Component({
  selector: 'app-viewer-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
/**
 * Represents the ViewerPatientComponent class.
 * This component is responsible for displaying patient information and handling selected study events.
 */
export class ViewerPatientComponent implements OnInit, OnChanges {
  constructor(
    private translateService: TranslateService,
    private store: Store,
  ) {}
  public selectedStudyUid: string;
  ngOnInit(): void {}
  @Input() studyUid: string = '';
  /**
   * Lifecycle hook that is called when one or more input properties of the component change.
   * @param changes - An object containing the changed properties.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.studyUid && changes.studyUid.currentValue !== undefined) {
      this.isAnonymousShare$ = this.store.select(ViewerGeneralQuery.selectIsAnonymous);
      this.patientInfo$ = this.store.select(ViewerGeneralQuery.selectPatientInfoByStudyUid(this.studyUid));
    }
  }

  //#region properties
  public patientInfo$: Observable<IPatientInfo | undefined>;
  public isAnonymousShare$: Observable<boolean>;

  /**
   * Handles the event when a study is selected.
   * @param $event The event object containing the selected study UID.
   */
  onSelectedStudy($event) {
    this.selectedStudyUid = $event.uid;
  }
}
