<div class="viewer-patient">
  <ng-container *rxLet="patientInfo$; let patient">
    <div class="viewer-patient__avatar">
      <img [src]="patient?.avatar !== '' ? patient?.avatar : './assets/images/age-gender/O.png'" alt="Patient's Avatar" />
    </div>
    <div class="viewer-patient__meta">
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientID</span>:
        <ng-container *ngIf="this.isAnonymousShare$ | async; else normalPatientId">
          <span translate>studyInfoAnonymousPatient</span>
        </ng-container>
        <ng-template #normalPatientId>
          <span>{{ patient?.patientId }}</span>
        </ng-template>
      </div>
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientName</span>:
        <ng-container *ngIf="this.isAnonymousShare$ | async; else normalPatientName">
          <span translate>studyInfoAnonymousPatient</span>
        </ng-container>
        <ng-template #normalPatientName>
          <span>{{ patient?.patientName }}</span>
        </ng-template>
      </div>
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientSex</span>:
        <span>{{ patient?.patientGender?.trim() || '' | translate }}</span>
      </div>
      <div class="viewer-patient__text">
        <span translate>studyInfoPatientAge</span>:
        <span>{{ patient?.patientAge }}</span>
      </div>
    </div>
  </ng-container>
</div>
