import { GoogleParams } from '@app/viewer/models';
import { createActionGroup, props } from '@ngrx/store';
import { IReportContent, IReportDir, IReportInfo } from '../models/';

export const reportActions = createActionGroup({
  source: 'Report',
  events: {
    /**
     * Action to load report data.
     */
    load: props<{ data: GoogleParams }>(),

    /**
     * Action dispatched when loading report data is successful.
     * @param payload - The loaded report data.
     * @param isReadOnly - A boolean indicating whether the report is read-only.
     * @param isShare - A boolean indicating whether the report is in share mode.
     */
    loadSuccess: props<{ payload: IReportInfo; isReadOnly: boolean; isShare: boolean; isAnonymous: boolean }>(),
    /**
     * Action dispatched when loading report data fails.
     * @param error - The error that occurred during loading.
     */
    loadFail: props<{ error: any }>(),

    /**
     * Action to load report data.
     */
    reload: props<{ reportId: string }>(),

    /**
     * Action dispatched when loading report data is successful.
     * @param payload - The loaded report data.
     */
    reloadSuccess: props<{ payload: IReportDir }>(),

    changeSelectedStudy: props<{ studyUid: string }>(),
  },
});
